<template>
    <AppLayout>
        <RequireLogin>
            <Snackbar :attr="snackbar" />
            <v-snackbar v-model="snackbarAccountDeleted" :timeout="2000" top color="blue" class="mt-0 pt-0">
                <span>Account deleted</span>
                <v-btn text class="blue white--text" @click="snackbarAccountDeleted = false"><font-awesome-icon icon="check" fixed-width/></v-btn>
            </v-snackbar>
            <v-snackbar v-model="snackbarAccountDeleteFailed" :timeout="2000" top color="red" class="mt-0 pt-0">
                <span>Failed to delete account</span>
                <v-btn text class="red white--text" @click="snackbarAccountDeleteFailed = false"><font-awesome-icon icon="check" fixed-width/></v-btn>
            </v-snackbar>
            <v-row justify="center" class="py-5">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <h1 class="text-h6 font-weight-light text-center">How to delete this account</h1>
                </v-col>
            </v-row>
            <!-- <v-row justify="center" class="py-5">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <h1 class="text-h6 font-weight-light">Account Settings</h1>
                <p class="text-overline mt-0 pt-0" style="font-size: 0.8em;">
                    <span>{{ currentAccount.type }} account</span>
                </p>
                </v-col>
            </v-row> -->

            <v-row justify="center" class="py-5">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <p class="text-overline mb-0">Account type</p>
                    <p>
                        <span>{{ currentAccount.type }}</span>
                    </p>
                    <p class="text-overline mb-0">Account name</p>
                    <p>
                        <span>{{ displayName }}</span>
                    </p>
                    <p class="text-overline mb-0">Account image</p>
                    <p>
                        <Avatar :attr="currentAccount" :size="36"/>
                    </p>

                    <template v-if="currentAccount.type === ACCOUNT_TYPE_FREE">
                    <p class="text-overline mb-0">Related accounts <span>({{ combinedAccountList.length - 1 }})</span></p>
                    <v-list class="ma-0 pa-0" v-if="combinedAccountList.length > 1">
                        <template v-for="(account, idx) in combinedAccountList">
                        <v-list-item @click="selectAccount(account.id)" v-bind:key="account.id" class="ma-0 py-1 px-4" v-show="account.id !== currentAccount.id">
                            <v-list-item-avatar tile style="margin-right: 8px;">
                                <Avatar :attr="account" :size="32"/>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title>{{ account.name }}</v-list-item-title>
                                <v-list-item-subtitle class="text-overline" style="margin-left: 2px">{{ account.type }}</v-list-item-subtitle>
                            </v-list-item-content>
                            <v-spacer></v-spacer>
                        </v-list-item>
                        <v-divider v-bind:key="idx"></v-divider>
                        </template>
                    </v-list>
                    </template>

                    <template v-if="currentAccount.type === ACCOUNT_TYPE_ENTERPRISE">
                    <p class="text-overline mb-0">Authentication realms <span>({{ realmList.length }})</span></p>
                    <v-list class="ma-0 pa-0" v-if="realmList.length > 0">
                        <template v-for="(realm, idx) in realmList">
                        <v-list-item v-bind:key="realm.id" class="ma-0 py-1 px-4">
                            <v-img contain :src="getRealmIcon(realm.icon)" v-if="realm.icon" max-width="34" max-height="34" class="mr-5"/>
                            <v-list-item-content class="text-overline" style="font-size: 14px!important">
                                {{ realm.label }}
                            </v-list-item-content>
                            <v-spacer></v-spacer>
                        </v-list-item>
                        <v-divider v-bind:key="idx"></v-divider>
                        </template>
                    </v-list>
                    </template>
                </v-col>
            </v-row>
            <v-row justify="center" class="py-5">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <!-- <p class="text-overline mb-0">Delete account</p> -->
                    <p>
                        <v-btn class="red--text" @click="dialogDeleteAccount = true" outlined :disabled="isDeleteAccountDisabled">
                            Delete account
                        </v-btn>
                    </p>
                    <p v-if="currentAccount.type === ACCOUNT_TYPE_FREE && combinedAccountList.length > 1" class="red--text">
                        The related accounts must be deleted, or you must unlink yourself from them, before the this account can be deleted.
                    </p>
                    <p v-if="currentAccount.type === ACCOUNT_TYPE_ENTERPRISE && realmList.length > 0" class="red--text">
                        The authentication realms must be deleted before the account can be deleted.
                    </p>
                    <v-dialog v-model="dialogDeleteAccount" max-width="600">
                        <v-card elevation="4" class="pa-5" style="border: solid 1px red;">
                            <v-card-text class="text-h6 pa-0 pb-3 red--text">Delete account</v-card-text>
                            <v-row justify="center" class="pt-3">
                                <v-col cols="12">
                                    <p class="font-weight-light text-body-1">This action is not reversible.</p>
                                    <p class="text-body-1 font-weight-light pb-2 red--text">All account data will be permanently deleted.</p>
                                </v-col>
                            </v-row>
                            <v-row justify="center">
                                <v-btn elevation="4" class="red white--text" @click="deleteAccount" :disabled="isDeleteAccountDisabled">
                                    Delete
                                </v-btn>
                                <v-btn text class="grey--text" @click="dialogDeleteAccount = false">Cancel</v-btn>
                            </v-row>
                        </v-card>
                    </v-dialog>
                </v-col>
            </v-row>

        </RequireLogin>
    </AppLayout>
</template>

<style lang="css" scoped>
/* p.pointer {
     cursor: pointer;
}
span.pointer {
     cursor: pointer;
} */
@media print {
  /* add margin around the main content to separate it from the page header and footer */
  @page {
    margin-top: 2cm;
    margin-bottom: 2cm;
  }
  /* removes padding corresponding to navbar and footer when printing document, adds one line of padding to top of document to separate page title from page content */
  .print {
    padding: 0 !important;
  }
  /* hides elements that should not appear in print */
  .no-print {
      display: none !important;
  }

}
</style>

<script>
import { mapState, mapGetters } from 'vuex';
import AppLayout from '@/components/AppLayout.vue';
import RequireLogin from '@/components/RequireLogin.vue';
import Snackbar from '@/components/Snackbar.vue';
import Avatar from '@/components/Avatar.vue';
// import TextLink from '@/components/TextLink.vue';
import { compact } from '@/sdk/input';
import loginshield from '@/client';
import { ACCOUNT_TYPE_ENTERPRISE, ACCOUNT_TYPE_PERSONAL, ACCOUNT_TYPE_FREE } from '@/constants';

export default {
    components: {
        AppLayout,
        RequireLogin,
        Snackbar,
        Avatar,
        // TextLink,
    },
    data() {
        return {
            snackbar: {},
            snackbarAccountDeleted: false,
            snackbarAccountDeleteFailed: false,
            dialogDeleteAccount: false,
            realmImage: [],
            realmMap: {},
        };
    },

    /*
    props: ['isAuthenticated', 'isReady', 'isGravatarEnabled'],
*/
    computed: {
        ...mapState({
            isReady: (state) => state.isReady,
            user: (state) => state.user,
            accountMap: (state) => state.accountMap,
            currentAccountId: (state) => state.accountId,
        }),
        ...mapGetters({
            currentAccount: 'account', // currently selected account object
            combinedAccountList: 'combinedAccountList',
        }),
        compactAccountNames() {
            return this.combinedAccountList.map((item) => compact(item.name));
        },
        currentAccountImageWithGravatarDisabled() {
            return { icon: this.currentAccount.icon, isGravatarEnabled: false };
        },
        isDeleteAccountDisabled() {
            return (this.currentAccount.type === ACCOUNT_TYPE_FREE && this.combinedAccountList.length > 1) || (this.currentAccount.type === ACCOUNT_TYPE_ENTERPRISE && this.realmList.length > 0);
        },
        compactRealmLabels() {
            return this.realmList.map((item) => compact(item.label || ''));
        },
        compactRealmDomains() {
            return this.realmList.map((item) => compact(item.domain || ''));
        },
        realmList() {
            const list = Object.values(this.realmMap);
            list.sort((a, b) => (`${a.label}`).localeCompare(b.label));
            console.log('list', list);
            return list;
        },
        ACCOUNT_TYPE_ENTERPRISE() {
            return ACCOUNT_TYPE_ENTERPRISE;
        },
        ACCOUNT_TYPE_PERSONAL() {
            return ACCOUNT_TYPE_PERSONAL;
        },
        ACCOUNT_TYPE_FREE() {
            return ACCOUNT_TYPE_FREE;
        },
    },

    watch: {
        // isGravatarEnabled(val, oldval) {
        //     console.log('isGravatarEnabled: %o (was: %o)', val, oldval);
        //     this.isGravatarEnabled = val;
        // },
        // Close when clicking outside of dialog
        isReady(value, oldValue) {
            console.log('account.vue: isReady changed from %o to %o', oldValue, value);
            // only call init again if ready changed from false to true after mounted()
            if (value && !oldValue) {
                this.init();
            }
        },
        dialogDeleteAccount(val) {
            if (!val) {
                this.closeDialogDeleteAccount();
            }
        },
    },

    created() {
        console.log('created'); // : isAuthenticated: %o', this.isAuthenticated);
        if (this.$route.query.acct) {
            const queryAccountId = this.$route.query.acct;
            if (queryAccountId !== this.currentAccountId) {
                console.log(`account.vue: setting current account to ${queryAccountId}`);
                this.$store.commit('setAccountId', queryAccountId);
            }
        }
        // if (!this.currentAccount.domain) {
        //     this.isDeveloperExpansionPanelOpen = [0];
        // }
    },

    mounted() {
        console.log('mounted');
        if (this.isReady) {
            this.init();
        }
    },

    methods: {
        async init() {
            // load concurrently:
            if (this.currentAccount.type === ACCOUNT_TYPE_ENTERPRISE) {
                await Promise.all([
                    this.fetchRealmList(),
                ]);
            }
            this.displayName = this.currentAccount.name;
        },
        async fetchRealmList() {
            try {
                const realmListResponse = await loginshield.account.getRealmList({ accountId: this.currentAccount.id });
                if (realmListResponse && realmListResponse.data) {
                    const map = {};
                    realmListResponse.data.forEach((item) => {
                        const itemCopy = { ...item };
                        if (itemCopy.android && typeof itemCopy.android === 'object' && typeof itemCopy.android.allowLoginFrom === 'object' && itemCopy.android.allowLoginFrom.length > 0) {
                            itemCopy.androidApplicationId = itemCopy.android.allowLoginFrom[0].applicationId;
                        } else {
                            itemCopy.androidApplicationId = null;
                        }
                        map[item.id] = itemCopy;
                    });
                    this.realmMap = map;
                    console.log('init realmMap: ', this.realmMap);
                }
            } catch (error) {
                console.log('fetchRealmList failed', error);
            }
        },
        async deleteAccount() {
            this.$store.commit('loading', { deleteAccount: true });
            const response = await this.$store.dispatch('deleteAccount', { accountId: this.currentAccount.id });
            if (response.deleted) {
                this.snackbarAccountDeleted = true;
                if (response.logout) {
                    // this.$router.push('/');
                    window.location.href = '/'; // return to public info site
                } else if (response.switchAccount) {
                    this.$router.push('/dashboard');
                }
            } else {
                console.log('account.vue: deleteAccount failed: %o', response);
                this.snackbarAccountDeleteFailed = true;
            }
            this.closeDialogDeleteAccount();
            this.$store.commit('loading', { deleteAccount: false });
        },
        closeDialogDeleteAccount() {
            this.dialogDeleteAccount = false;
        },
        getRealmIcon(imageBase64) {
            console.log('getRealmIcon');
            if (imageBase64) {
                return `data:image/png;base64,${imageBase64}`;
            }
            return '';
        },
        async selectAccount(id) {
            await this.$store.dispatch('switchAccount', id);
            this.$router.push({ path: '/dashboard', query: { acct: id } });
        },
    },
};
</script>
